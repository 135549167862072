import {createContext, PropsWithChildren, useContext, useState} from "react";

const EndOfBookPopupContext = createContext<{
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	wasTheBookGuessed: boolean;
	setWasTheBookGuessed: (wasTheBookGuessed: boolean) => void;
} | undefined>(undefined);


export default function useEndOfBookPopupContext() {
	const context = useContext(EndOfBookPopupContext);
	if (!context) {
		throw new Error("useEndOfBookContext must be used within a EndOfBookContextProvider");
	}
	return context;
}

export function EndOfBookPopupContextProvider(props: PropsWithChildren<{}>) {
	const [isOpen, setIsOpen] = useState(false);
	const [wasTheBookGuessed, setWasTheBookGuessed] = useState(false);

	return (
		<EndOfBookPopupContext.Provider
			value={{isOpen, setIsOpen, wasTheBookGuessed, setWasTheBookGuessed}}>
			{props.children}
		</EndOfBookPopupContext.Provider>
	)
}