export const gutenbergToFileName: Record<string, string> = {
	"11": "Alice",
	"10": "Bible",
	"345": "Dracula",
	"84": "Frankenstein",
	"76": "Gatsby",
	"2701": "Moby",
	"2600": "WarAndPeace",
	"1513": "RomeoAndJuliet",
	"120": "TreasureIsland",
	"1184": "MonteCristo",
	"1727": "Odyssey",
	"35": "TimeMachine",
	"174": "DorianGray",
	"43": "JekyllAndHyde",
	"18857": "CentreOfTheEearth",
	"1228": "OriginOfSpecies",
	"16": "PeterPan",
}
