import {Box, Button, IconButton, Typography} from "@mui/material";
import {useConsentContext} from "../context/ConsentContext";
import CloseIcon from '@mui/icons-material/Close';

export function CookieBanner() {
	const {googleAnalyticsConsentGiven, setGoogleAnalyticsConsentGiven} = useConsentContext()

	return (
		<Box sx={{position: "absolute", left: 0, right: 0, bottom: 0}}>
			{googleAnalyticsConsentGiven === undefined &&
                <Typography variant="body1"
                            sx={{
					            textAlign: "center",
					            backgroundColor: "rgba(0,0,0,0.5)",
					            color: "white",
					            padding: "10px"
				            }}>
                    To help us improve the game we use cookies. But for that we need your consent. Press <Button
                    onClick={() => setGoogleAnalyticsConsentGiven(true)}>here</Button> if you agree. You can always take
                    away consent in the options
                    menu.
                    <IconButton onClick={() => setGoogleAnalyticsConsentGiven(false)}>
                        <CloseIcon sx={{cursor: "pointer"}}/>
                    </IconButton>
                </Typography>

			}
		</Box>
	)
}