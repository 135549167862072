import {IconButton, InputAdornment, Paper, Stack, TextField, Tooltip} from "@mui/material";
import {WordCountTable} from "./WordCountTable";
import React, {useEffect, useRef, useState} from "react";
import {
	BookDataV4,
	PlayerPreviousTries,
	WordWithAlternatives,
	WordWithCount,
	WordWithIsGuessed
} from "../model/BookData";
import {loadJsonData} from "../helpers/JsonOperations";
import {useNavigate, useParams} from "react-router-dom";
import {gutenbergToFileName} from "../model/Books";
import SendIcon from '@mui/icons-material/Send';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import {useDifficultyContext} from "../context/DifficultyContextProvider";
import FirebaseManager from "../firebase/FirebaseManager";
import {CookieBanner} from "./CookieBanner";
import {EndOfBookPopup} from "./EndOfBookPopup";
import {HiddenBookTitle} from "./HiddenBookTitle";
import useEndOfBookPopupContext from "../context/EndOfBookPopupContext";

export function Game() {
	const [fullData, setFullData] = useState<BookDataV4>();

	const [guessedWords, setGuessedWords] = useState<PlayerPreviousTries[]>([]);
	const [input, setInput] = useState('');
	const [inputStatus, setInputStatus] = useState<string>('');
	const [lastWord, setLastWord] = useState<PlayerPreviousTries>();
	const [title, setTitle] = useState<(WordWithIsGuessed & WordWithAlternatives)[]>([]);
	const [hints, setHints] = useState<WordWithCount[]>([]);
	const {isOpen, setIsOpen, setWasTheBookGuessed} = useEndOfBookPopupContext();

	const inputRef = useRef();
	const navigate = useNavigate();

	const {key} = useParams()
	const {getNewBook} = useDifficultyContext();

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Enter' && isOpen) {
				getNewBook();
			}
		};


		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen, getNewBook,]);

	useEffect(() => {
		if (isOpen) {
			FirebaseManager.addAnalyticsEvent('game_finished', {
				time: new Date().toISOString(),
				book: key,
				wordsGuessed: guessedWords,
			})
		}
	}, [isOpen, key, guessedWords]);

	useEffect(() => {
		if (key) {
			const name = gutenbergToFileName[key];
			if (!name) getNewBook();
			try {
				loadJsonData(name).then((data) => setFullData(data));
				setGuessedWords([])
				setInput('')
				setInputStatus('')
				setLastWord(undefined)
				setIsOpen(false)
				setTitle([])
				setHints([])
			} catch (e) {
				console.error(e);
				getNewBook()
			}
		} else {
			getNewBook()
		}
	}, [key, navigate, getNewBook, setIsOpen]);

	useEffect(() => {
		if (fullData) {
			setTitle(fullData.title)
			// TODO to enable percentile comment out from here
			setGuessedWords(fullData.curatedWords.slice(0, fullData.wordsToShowInitially));
			setHints(fullData.curatedWords.slice(fullData.wordsToShowInitially, fullData.curatedWords.length));
			// TODO to here
		}
	}, [fullData]);

	// TODO to enable percentile uncomment out from here
	// useEffect(() => {
	// 	if (fullData) {
	// 		prepareHints(fullData);
	// 	}
	// }, [fullData, hintsByPercentile]);
	//
	// useEffect(() => {
	// 	if (!!fullData && guessedWords.length < fullData.wordsToShowInitially && hints.length > 0) {
	// 		getHint();
	// 	}
	// }, [hints, fullData, guessedWords]);
	//
	// function prepareHints(_fullData: BookDataV4) {
	// 	if (hintsByPercentile) {
	// 		const hints = _fullData.words.slice(
	// 			Math.floor(_fullData.words.length * (percentileLowerBound / 100)),
	// 			Math.floor(_fullData.words.length * (percentileUpperBound / 100))
	// 		);
	//
	// 		setHints(hints.sort(() => Math.random() - 0.5))
	// 	} else {
	// 		setHints(_fullData.curatedWords.slice(_fullData.wordsToShowInitially, _fullData.curatedWords.length));
	// 	}
	// }
	// TODO to here

	// @ts-ignore
	const handleInputChange = (event) => {
		setInput(event.target.value.toLowerCase());
	};

	const handleInputSubmit = () => {
		const playerGuess = input.trim();
		if (playerGuess !== "") {
			playerGuess.split(" ").forEach((word) => {
				processWord(word);
			})
		}
	}

	// @ts-ignore
	const processWord = (wordToBeChecked: string) => {
		const wordInBook = fullData?.words.find((item) => item.word === wordToBeChecked);
		const wordsInTitle = title.filter((item) => item.word.toLowerCase().replace(/[^a-zA-Z\\-]/g, "") === wordToBeChecked);
		const isAnAlternative = !!(title.find((item) => item.alternatives.some((alternative) => alternative.toLowerCase() === wordToBeChecked)))

		if (guessedWords.some((item) => item.word === wordToBeChecked)) {
			setInputStatus('duplicate');

			return;
		}

		const guessedWordWithData: PlayerPreviousTries = {
			word: wordToBeChecked,
			count: wordInBook?.count ?? 0,
			isGuessed: wordsInTitle.length > 0,
			isAnAlternative: isAnAlternative
		}

		if (wordsInTitle.length > 0) {
			wordsInTitle.forEach((item) => item.isGuessed = true);

			if (title.every((a) => a.isGuessed)) {
				showVictoryDialog();
			}
		}

		if (hints.some((hint) => hint.word === wordToBeChecked)) {
			setHints(hints.filter((hint) => hint.word !== wordToBeChecked))
		}

		setLastWord(guessedWordWithData);
		setGuessedWords(prevData => [...prevData, guessedWordWithData]);
		setInputStatus(isAnAlternative ? 'alternative' : wordInBook ? 'correct' : 'incorrect');
		setInput('');
		setTimeout(() => setInputStatus(''), 5000);
		if (inputRef.current) {
			// @ts-ignore
			inputRef.current.focus();
		}
	};

	function showVictoryDialog() {
		setWasTheBookGuessed(true);
		setIsOpen(true);
	}

	function getHint() {
		if (hints.length > 0) {
			// check if any of the guessed words is in the hints and remove it, to not try to do the same hint again and get a duplicte error
			if (hints.some((hint) => guessedWords.some((guessedWord) => guessedWord.word === hint.word))) {
				setHints(hints.filter((hint) => !guessedWords.some((guessedWord) => guessedWord.word === hint.word)))
			}

			setInput(hints[0].word)
			processWord(hints[0].word)
		}
	}

	return (
		<Paper elevation={3} style={{padding: '20px', maxHeight: "100vh"}}>
			{fullData && <HiddenBookTitle title={title}/>}
			<Stack direction={"row"} spacing={2} sx={{mb: 2, height: "50px"}} alignItems={"flex-start"}>
				<TextField
					inputRef={inputRef}
					size={"small"}
					label="Guess word"
					variant="outlined"
					fullWidth
					value={input}
					onChange={handleInputChange}
					onKeyUp={(event) => {
						if (event.key === 'Enter' || event.key === "Done" || event.key === "Go") {
							event.preventDefault();
							handleInputSubmit();
						}
					}}
					error={inputStatus === 'incorrect' || inputStatus === 'duplicate'}
					helperText={
						inputStatus === 'correct' ? `Word is in the book ${lastWord?.count} times!` :
							inputStatus === 'incorrect' ? `Word is not in the book!` :
								inputStatus === 'duplicate' ? 'Word was already checked!' :
									inputStatus === 'alternative' ? "You're so close" : ''
					}
					sx={{
						'& .MuiOutlinedInput-root': {
							borderColor: inputStatus === 'correct' ? 'green'
								: inputStatus === 'incorrect' ? 'orange'
									: inputStatus === 'duplicate' ? 'red'
										: inputStatus === 'alternative' ? 'blue'
											: 'inherit'
						}
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={handleInputSubmit}>
									<SendIcon/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				{hints.length > 0 &&
                    <Tooltip title={"Get hint"}>
                        <IconButton onClick={getHint}>
                            <PsychologyAltIcon/>
                        </IconButton>
                    </Tooltip>
				}
			</Stack>
			<WordCountTable data={guessedWords} lastWord={lastWord}/>
			<CookieBanner/>
			<EndOfBookPopup
				title={title}
			/>
		</Paper>
	)
}