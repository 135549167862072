import {
	Box,
	Button,
	CssBaseline,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	Link,
	Stack,
	Switch,
	Typography
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';

import React, {useState} from "react";
import {useDifficultyContext} from "../context/DifficultyContextProvider";
import {useConsentContext} from "../context/ConsentContext";
import useEndOfBookPopupContext from "../context/EndOfBookPopupContext";

export default function Options(props: { darkMode: boolean, setDarkMode: (newValue: boolean) => void }) {
	const {darkMode, setDarkMode} = props;
	const [open, setOpen] = useState(false);
	const {googleAnalyticsConsentGiven, setGoogleAnalyticsConsentGiven} = useConsentContext();

	const {setShowWelcomePopup} = useDifficultyContext();
	const {showWordLength, setShowWordLength} = useDifficultyContext();
	const {setWasTheBookGuessed, setIsOpen} = useEndOfBookPopupContext();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Box>
			<CssBaseline/>
			<IconButton sx={{position: "absolute", right: 0}} color="inherit" onClick={handleClickOpen}>
				<SettingsIcon/>
			</IconButton>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Settings</DialogTitle>
				<DialogContent>
					<Stack spacing={2}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setWasTheBookGuessed(false)
								setIsOpen(true)
								handleClose()
							}}
						>Stuck? Try a different book!</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setShowWelcomePopup(true)
								handleClose()
							}}
						>Show welcome popup (instructions)</Button>
						<FormControlLabel
							control={
								<Switch
									checked={darkMode}
									onChange={() => setDarkMode(!darkMode)}
									name="darkMode"
									color="primary"
								/>
							}
							label="Dark Mode"
						/>
						<FormControlLabel
							control={
								<Switch
									checked={showWordLength}
									onChange={() => setShowWordLength(!showWordLength)}
									name="showWordLength"
									color="primary"
								/>
							}
							label="Show word length"
						/>
						{/*<FormControlLabel*/}
						{/*	control={*/}
						{/*		<Switch*/}
						{/*			checked={hintsByPercentile}*/}
						{/*			onChange={() => setHintsByPercentile(!hintsByPercentile)}*/}
						{/*			name="hintsByPercentile"*/}
						{/*			color="primary"*/}
						{/*		/>*/}
						{/*	}*/}
						{/*	label="Hints by percentile game mode"*/}
						{/*/>*/}
						<Typography variant={"h6"}>Consent</Typography>
						<FormControlLabel
							control={
								<Switch
									disabled
									checked={true}
									name="gameCookies"
									color="primary"
								/>
							}
							label="Consent to using game cookies"
						/>
						<FormControlLabel
							control={
								<Switch
									checked={googleAnalyticsConsentGiven}
									onChange={() => setGoogleAnalyticsConsentGiven(!googleAnalyticsConsentGiven)}
									name="googleAnalyticsConsentGiven"
									color="primary"
								/>
							}
							label="Consent to using google analytics"
						/>
						<Typography variant={"h6"}>About</Typography>
						<Typography sx={{pt: 1}}>Content processed from <Link target={"_blank"}
						                                                      href={"https://www.gutenberg.org/"}>Project
							Gutenberg</Link> </Typography>
						<Typography sx={{pt: 1}}>If you feel like it <Link target={"_blank"}
						                                                   href={"https://buymeacoffee.com/eggsamplegames/"}>buy
							us a slice of pizza</Link>
						</Typography>
						<Typography sx={{pt: 1}}>Report bugs at <Link target={"_blank"}
						                                              href={"mailto:info@lexicount.fun"}>info@lexicount.fun</Link>
						</Typography>
					</Stack>
				</DialogContent>
			</Dialog>
		</Box>
	)
}