import {Button, Dialog, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import {gutenbergToFileName} from "../model/Books";
import {useDifficultyContext} from "../context/DifficultyContextProvider";

export function WelcomePopup() {
	const {showWelcomePopup, setShowWelcomePopup} = useDifficultyContext();

	const handleClose = () => {
		setShowWelcomePopup(false);
		localStorage.setItem("welcomePopupClosed", "true")
	};

	return (
		<Dialog open={showWelcomePopup} onClose={handleClose}>
			<DialogTitle>Welcome to Lexicount!</DialogTitle>
			<DialogContent>
				<Stack>
					<Typography variant="body1" gutterBottom sx={{
						pb: 2,
						maxWidth: "450px"
					}}>
						In this game your objective is to guess a book that is in public domain, which means that the
						book’s author has been dead longer than 70 years. The selection of books is limited, so the
						books themselves are well known.
						<br/>
						<br/>
						The title is replaced by numbers that represent how many times the word appears in the book.
						<br/>
						<br/>
						You can access difficulty options by clicking the settings icon<SettingsIcon
						fontSize={"small"}/> in the top right corner.
						<br/>
						There is also a hint icon <PsychologyAltIcon fontSize={"small"}/> that will show you an extra
						word to help you along.
						<br/><br/>
						To play the game you try to guess the words that appear in the title of the book. Every guess
						you
						make will reveal how often the guessed word appears in the book. If you correctly guess a word
						from the title, that part of the title will be revealed. As a starting help a few words are
						already given along with the number of their appearances.
						<br/><br/>
						Sometimes a guessed word will be marked in <span style={{color: "orange"}}>orange</span>. That
						means that it's very close to a word in the title. You might want to try a different spelling,
						or perhaps change the word from singular to plural.
						<br/><br/>
						For now there are only {Object.keys(gutenbergToFileName).length} books to choose from. So
						replayability is limited, but we are working on it.
						<br/><br/>
					</Typography>
					<Button variant={"contained"} onClick={handleClose}>Start the game!</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	)
}