import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyBt5_yRZqGnb48A1UfZxI9l271ofD75RcU",
	authDomain: "booker-be6b7.firebaseapp.com",
	projectId: "booker-be6b7",
	storageBucket: "booker-be6b7.appspot.com",
	messagingSenderId: "638839345401",
	appId: "1:638839345401:web:025549a5afcf36d955d228",
	measurementId: "G-4RF4BH0HJT"
};

class FirebaseManager {
	analytics: any;

	get consent() {
		return localStorage.getItem("googleAnalyticsConsent") === "true";
	}

	loadAnalytics() {
		const app = initializeApp(firebaseConfig);
		this.analytics = getAnalytics(app);
	}

	unloadAnalytics() {
		this.analytics = null;
	}

	addAnalyticsEvent(eventName: string, eventParams: any) {
		try {

			if (!this.consent) return;
			if (!this.analytics) return;
			logEvent(this.analytics, eventName, eventParams)
		} catch (e) {
			console.error(e)
		}
	}
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new FirebaseManager()

