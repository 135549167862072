import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import FirebaseManager from "../firebase/FirebaseManager";

const ConsentContext = createContext<{
	googleAnalyticsConsentGiven: boolean | undefined,
	setGoogleAnalyticsConsentGiven: (value: boolean) => void,

} | null>(null)

export function useConsentContext() {
	const context = useContext(ConsentContext);
	if (context === null) {
		throw new Error('useConsentContext must be used within a ConsentContext');
	}
	return context;
}

export function ConsentContextProvider(props: PropsWithChildren<{}>) {
	const [googleAnalyticsConsentGiven, setGoogleAnalyticsConsentGiven] = useState(
		localStorage.getItem("googleAnalyticsConsent") === "true" ? true : localStorage.getItem("googleAnalyticsConsent") === "false" ? false : undefined
	);

	useEffect(() => {
		if (googleAnalyticsConsentGiven) {
			localStorage.setItem("googleAnalyticsConsent", "true")
			FirebaseManager.loadAnalytics()
		} else {
			localStorage.removeItem("googleAnalyticsConsent")
			FirebaseManager.unloadAnalytics()
		}
	}, [googleAnalyticsConsentGiven]);


	return (
		<ConsentContext.Provider value={{googleAnalyticsConsentGiven, setGoogleAnalyticsConsentGiven}}>
			{props.children}
		</ConsentContext.Provider>
	)
}