import React from 'react';
import './App.css';
import {Box, createTheme, ThemeProvider} from "@mui/material";
import {Game} from "./components/Game";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Options from "./components/Options";
import {DifficultyContextProvider} from "./context/DifficultyContextProvider";
import {WelcomePopup} from "./components/WelcomePopup";
import FirebaseManager from "./firebase/FirebaseManager";
import {ConsentContextProvider} from "./context/ConsentContext";
import {EndOfBookPopupContextProvider} from "./context/EndOfBookPopupContext";


FirebaseManager.addAnalyticsEvent('game_opened', {
	time: new Date().toISOString(),
});

function App() {
	const [darkMode, setDarkMode] = React.useState(true);

	const theme = createTheme({
		palette: {
			mode: darkMode ? 'dark' : 'light',
		}
	})

	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<ConsentContextProvider>
					<DifficultyContextProvider>
						<EndOfBookPopupContextProvider>
							<Options darkMode={darkMode} setDarkMode={setDarkMode}/>
							<WelcomePopup/>
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								className="App"
								sx={{
									minHeight: "100vh",
									maxHeight: "100vh",
									backgroundColor: theme.palette.background.default
								}}
							>
								<Routes>
									<Route path={"/"} Component={Game}/>
									<Route path={"/:key"} Component={Game}/>
								</Routes>
							</Box>
						</EndOfBookPopupContextProvider>
					</DifficultyContextProvider>
				</ConsentContextProvider>
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;

