import {Button, Modal, Paper, Stack, Typography} from "@mui/material";
import React from "react";
import {WordWithAlternatives, WordWithIsGuessed} from "../model/BookData";
import {useDifficultyContext} from "../context/DifficultyContextProvider";
import useEndOfBookPopupContext from "../context/EndOfBookPopupContext";

export function EndOfBookPopup(props: {
	title: (WordWithIsGuessed & WordWithAlternatives)[]
}) {
	const {title} = props;
	const {getNewBook} = useDifficultyContext();
	const {isOpen, wasTheBookGuessed} = useEndOfBookPopupContext();

	return (
		<Modal
			open={isOpen}
			aria-labelledby="victory-modal-title"
			aria-describedby="victory-modal-description"
		>
			<Paper elevation={3} style={{
				padding: '20px',
				marginTop: '20px',
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
			}}
			>
				<Stack spacing={2} alignItems={"center"} sx={{maxWidth: "400px"}}>
					<Typography variant="h4" gutterBottom>
						{wasTheBookGuessed ? "Congratulations! You have correctly guessed" : "The book title was: "}
						<strong>{title.map(titleWord => titleWord.word).join(" ")}</strong>
						!
					</Typography>
					<Typography variant="body1" gutterBottom>
						Thank you very much for playing!
						<br/><br/>
						In case you have feedback for us, check out the settings window (top right).
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={getNewBook}
					>Guess another book!</Button>
				</Stack>
			</Paper>
		</Modal>
	)
}