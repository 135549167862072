import React, {PropsWithChildren} from "react";
import {Box, Paper, SxProps, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";

import {PlayerPreviousTries, WordWithCount} from "../model/BookData";

export function WordCountTable(props: PropsWithChildren<{
	data: PlayerPreviousTries[],
	lastWord?: PlayerPreviousTries,
}>) {
	const {data, lastWord} = props;

	return (
		<Box>
			<Typography variant="body1" gutterBottom sx={{pb: 2}}>
				The amount of times a word appears in the book:
			</Typography>
			<TableContainer component={Paper} sx={{overflow: "auto", maxHeight: "400px"}}>
				<Table size="small">
					<TableBody>
						{lastWord && <WordCountTableRow wordData={lastWord} sx={{border: "2px solid"}}/>}
						{data
							.filter((row: WordWithCount) => row.word !== lastWord?.word.toLowerCase())
							.sort((a, b) => b.count - a.count)
							.map((row: PlayerPreviousTries
							) => (
								<WordCountTableRow key={row.word} wordData={row}/>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

function WordCountTableRow(props: {
	wordData: PlayerPreviousTries,
	sx?: SxProps,
}) {
	const {wordData} = props;

	return (
		<TableRow key={wordData.word} sx={{
			borderBottom: '1px solid grey',
			backgroundColor: wordData.isGuessed ? 'green' : wordData.isAnAlternative ? 'orange' : 'inherit',
			...props.sx
		}}
		>
			<TableCell component="th" scope="row">
				{wordData.word.toLowerCase()}
			</TableCell>
			<TableCell align="right">{wordData.count}</TableCell>
		</TableRow>
	)
}
