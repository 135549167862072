import {createContext, PropsWithChildren, useCallback, useContext, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {gutenbergToFileName} from "../model/Books";

const DifficultyContext = createContext<{
	showWordLength: boolean,
	setShowWordLength: (value: boolean) => void,
	getNewBook: () => void,
	showWelcomePopup: boolean,
	setShowWelcomePopup: (value: boolean) => void,
	hintsByPercentile: boolean,
	setHintsByPercentile: (value: boolean) => void,
	percentileLowerBound: number,
	percentileUpperBound: number
} | null>(null)

export function useDifficultyContext() {
	const context = useContext(DifficultyContext);
	if (context === null) {
		throw new Error('useDifficultyContext must be used within a DifficultyContext');
	}
	return context;
}

export function DifficultyContextProvider(props: PropsWithChildren<{}>) {
	const [showWordLength, setShowWordLength] = useState(false);
	const [hintsByPercentile, setHintsByPercentile] = useState(false);

	const percentileLowerBound = 5;
	const percentileUpperBound = 15;

	// TODO move this to a different context
	const [showWelcomePopup, setShowWelcomePopup] = useState(!localStorage.getItem("welcomePopupClosed"));

	const {key} = useParams()
	const navigate = useNavigate();

	// TODO move this to a different context
	const getNewBook = useCallback(() => {
		const books = Object.keys(gutenbergToFileName);
		const randomBook = books[Math.floor(Math.random() * books.length)];
		if (randomBook === key) {
			getNewBook()
		} else {
			navigate(`../${randomBook}`)
		}
	}, [navigate, key]);

	return (
		<DifficultyContext.Provider value={{
			showWordLength,
			setShowWordLength,
			getNewBook,
			showWelcomePopup,
			setShowWelcomePopup,
			hintsByPercentile,
			setHintsByPercentile,
			percentileLowerBound,
			percentileUpperBound
		}}>
			{props.children}
		</DifficultyContext.Provider>
	)
}