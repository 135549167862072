import {BookDataV4, WordWithAlternatives, WordWithCount, WordWithIsGuessed} from "../model/BookData";

export async function loadJsonData(book: string): Promise<BookDataV4> {
	const response = await fetch(`/data/${book}.json`)
	const data = await response.json();
	data.words = Object.keys(data.words).map(key => ({word: key, count: data.words[key]}))

	const version = data.version ?? 1;

	return {
		title: getTitle(data, version),
		words: getWords(data, version),
		curatedWords: getCuratedWords(data, version),
		gutenbergID: version >= 2 ? data.gutenbergID : -1,
		wordsToShowInitially: version >= 4 ? data.wordsToShowInitially : 5
	};
}


function getTitle(data: any, version: number): (WordWithAlternatives & WordWithIsGuessed)[] {
	return version >= 3 ? data.title.map((wordData: (WordWithAlternatives & WordWithIsGuessed)) => ({
		word: wordData.word,
		count: wordData.count,
		isGuessed: wordData.isGuessed ?? false,
		alternatives: wordData.alternatives
	})) : data.title.split(" ").map((word: string) => ({
		word: word,
		count: data.words.find((a: WordWithCount) => a.word === word.toLowerCase())?.count ?? 0,
		isGuessed: false,
		alternatives: []
	}));
}

function getWords(data: any, version: number): WordWithCount[] {
	return data.words
		.map((word: WordWithCount) => ({word: word.word, count: word.count}))
		.sort((a: WordWithCount, b: WordWithCount) => b.count - a.count)
		;
}

function getCuratedWords(data: any, version: number): WordWithIsGuessed[] {
	return version >= 2 ? data.curatedWords.map((wordData: WordWithCount) => ({
		word: wordData.word,
		count: data.words.find((a: WordWithCount) => a.word === wordData.word.toLowerCase())?.count ?? 0,
		isGuessed: false
	})) : data.curatedWords.map((word: string) => ({
		word: word,
		count: data.words.find((a: WordWithCount) => a.word === word.toLowerCase())?.count ?? 0,
		isGuessed: false
	}));
}