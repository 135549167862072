import {Stack, Typography} from "@mui/material";
import React from "react";
import {WordWithAlternatives, WordWithIsGuessed} from "../model/BookData";
import {useDifficultyContext} from "../context/DifficultyContextProvider";

export function HiddenBookTitle(props: {
	title: (WordWithIsGuessed & WordWithAlternatives)[],
}) {
	const {title} = props;

	const {showWordLength} = useDifficultyContext()

	return (
		<Stack alignItems={"center"}>
			<Typography variant="body1" gutterBottom>Hidden book title:</Typography>
			<Stack sx={{mx: 2, mb: 3}} direction={"row"} spacing={2} justifyContent={"center"}
			       alignContent={"center"}>
				{title.map((titleWordData, index) =>
					titleWordData.isGuessed ?
						<Typography key={index}>{titleWordData.word}</Typography>
						:
						<Stack key={index}>
							{showWordLength &&
                                <Typography
                                    fontSize={10}
                                    sx={{whiteSpace: 'nowrap'}}>{"_ ".repeat(titleWordData.word.length)}</Typography>
							}
							<Typography>{titleWordData.count}x</Typography>
						</Stack>
				)}
			</Stack>
		</Stack>
	);
}